@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/scss/controller';

html,
body,
.font-body {
  scroll-behavior: smooth;
}

.body-margin {
  @apply mb-32 sm:mb-20;
}

.highlighted-text-modal-1,
.highlighted-text-modal-2 {
  @apply text-baselg underline text-ace-teal-800 leading-loosest;

  &:hover {
    @apply text-ace-teal-950;
  }

  &.large {
    @apply text-md;
  }

  &.small {
    @apply text-base;
  }

  &.xsmall {
    @apply text-sm;
  }
}

//fa-icon overides for ssr resizing issue https://github.com/FortAwesome/angular-fontawesome/issues/48
.svg-inline--fa {
  vertical-align: -0.125em;
}

fa-icon svg {
  display: inline-block;
  font-size: inherit;
  height: 1em;
}

fa-icon .fa-2x {
  font-size: 2em;
}

.sticky-header {
  @apply fixed top-0 bg-white block z-20 w-full;
  & + div.mx-auto {
    @apply mt-sticky;
  }
}
.triangle-pointer-up {
  width: 0px;
  height: 0px;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-bottom: 1em solid white;
}

h1 {
  @apply text-5xl font-extrabold leading-looser mb-6;
  @media (max-width: 640px) {
    @apply text-2xl mb-4;
  }
  span {
    @media (max-width: 640px) {
      @apply text-2xl mb-4;
    }
  }
}
h2 {
  @apply text-xl font-extrabold leading-looser mb-4;
  @media (max-width: 640px) {
    @apply text-2lg mb-3;
  }
}
h3 {
  @apply text-lg font-extrabold leading-looser mb-3;
  @media (max-width: 640px) {
    @apply text-lg;
  }
}
h4 {
  @apply text-md font-extrabold leading-looser mb-3;
}
h5,
h6 {
  @apply text-xs font-bold;
  @apply leading-5;
}
input::placeholder {
  @apply mb-2 font-extrabold leading-looser text-sm;
}
.eyebrow {
  @apply font-extrabold text-sm uppercase leading-5 tracking-wider mb-2 text-vis-grey-3;
}
.ace-home-hero-gradient {
  background: linear-gradient(90deg, #12a1cd, #12cdca);
}
.wysiwyg,
.wysiwyg-card {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      @apply mt-0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:last-child {
      @apply mb-0;
    }
  }

  h5 {
    &.pink {
      @apply text-ace-pink-600;
    }

    &.grey,
    &.grey-60 {
      @apply text-ace-grey-600;
    }

    &.teal {
      @apply text-ace-teal-600;
    }
  }

  a,
  .highlighted-text-modal-1,
  .highlighted-text-modal-2 {
    @apply text-baselg underline text-link-light-default leading-loosest;

    &.large {
      @apply text-md;
    }

    &.small {
      @apply text-base;
    }

    &.xsmall {
      @apply text-sm;
    }
    &.primary-button {
      @apply text-cta-primary-default mt-3 no-underline;
      width: max-content !important;
      @media (max-width: 640px) {
        width: 100% !important;
      }
      &:hover {
        @apply bg-cta-primary-hover;
      }
      &:active {
        @apply bg-cta-primary-active;
      }
    }
    &.secondary-button {
      @apply text-cta-secondary-default mt-3 no-underline;
      width: max-content !important;
      @media (max-width: 640px) {
        width: 100% !important;
      }
      &:hover {
        @apply bg-cta-secondary-hover text-cta-primary-default border-cta-primary-default;
      }
      &:active {
        @apply text-cta-secondary-active border-cta-secondary-active;
      }
    }
    &.tertiary-button {
      @apply text-cta-tertiary-default mt-3 underline underline-offset-[6px] decoration-2;
      &:hover {
        @apply text-ace-teal-800;
      }
      &:active {
        @apply text-cta-tertiary-active;
      }
    }
    &.tertiary-button.with-arrow {
      @apply no-underline text-visBtn-tertiary whitespace-nowrap;
      &::after {
        content: '';
        display: inline-block;
        mask: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>')
          no-repeat center;
        -webkit-mask: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>')
          no-repeat center;
        mask-size: contain;
        -webkit-mask-size: contain;
        width: 1em;
        height: 1em;
        @apply ml-1 bg-visBtn-tertiary;
      }
      &:hover {
        @apply text-visBtn-tertiaryHover;
        &::after {
          @apply bg-visBtn-tertiaryHover;
        }
      }
    }
    &.v-button {
      @apply mx-auto;
      &.\!w-6\/12 {
        width: 50% !important;
      }
      &.\!w-9\/12 {
        width: 75% !important;
      }
    }
  }

  p {
    @apply mb-2 font-normal;
  }
  p:not(.eyebrow) + h1 {
    @apply mt-12;

    @media (max-width: 640px) {
      @apply mt-8;
    }
  }

  p:not(.eyebrow) + h2 {
    @apply mt-8;

    @media (max-width: 640px) {
      @apply mt-6;
    }
  }

  p:not(.eyebrow) + h3,
  p:not(.eyebrow) + h4 {
    @apply mt-6;
  }

  p,
  span {
    @apply text-baselg leading-loosest;

    &.xxlarge {
      @apply text-xl mb-4 font-extrabold leading-tight;
      p,
      span {
        @apply text-xl;
      }
      @media (max-width: 640px) {
        @apply text-[28px] mb-3;
        p,
        span {
          @apply text-[28px];
        }
      }
    }

    &.xlarge {
      @apply text-lg mb-3 font-extrabold leading-tight;
      p,
      span {
        @apply text-lg;
      }
    }

    &.extra-large {
      @apply text-lg;
      p,
      span {
        @apply text-lg;
      }
    }

    &.large {
      @apply text-md;
      a {
        @apply text-md;
      }
    }

    &.small {
      @apply text-base;
      a {
        @apply text-base;
      }
    }

    &.xsmall {
      @apply text-sm;
      a {
        @apply text-sm;
      }
    }

    &:last-child {
      @apply mb-0;
    }
  }

  h1 span {
    font-size: inherit;
    line-height: inherit;
  }

  strong {
    @apply font-extrabold;
    p,
    span {
      @apply font-extrabold;
    }
  }

  ul,
  ol {
    @apply list-disc mb-v-spacing;
    margin-block-start: 0px;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 32px;

    li {
      @apply mb-2;
      &:last-child {
        @apply mb-0;
      }

      a {
        font-size: inherit;
      }
    }
  }

  ol {
    @apply list-decimal;
  }

  figure {
    @apply mb-8;
    max-width: 100% !important;

    img {
      display: inline-block;
      &.hidden {
        display: none;
      }
    }
    figcaption {
      text-align: center;
    }
  }

  &.headingConfig {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }

  table {
    @apply w-full;

    th,
    td {
      @apply p-1.5;
      text-align: initial;
    }

    tr {
      @apply border-b border-solid;
      border-color: #f2f2f3;
    }
  }

  .eyebrow {
    @apply font-extrabold text-sm leading-looser mb-2 tracking-wider uppercase mb-2 text-vis-grey-3;
    a {
      @apply text-sm;
    }
  }

  .bottom-content {
    @apply mt-0 pt-4;
  }

  .bottom-content.bottom-content-title {
    @apply mb-[-1rem];
  }

  .disclaimer {
    @apply text-xs;
    a {
      @apply text-xs;
    }
  }

  .disclaimer-sm {
    @apply text-xxs leading-3;
  }
}
.bg-vis-dark,
.bg-vis-alt-2,
.bg-ace-grey-900,
.bg-ace-teal-600,
.bg-ace-blue-600,
.from-ace-teal-600,
.from-ace-blue-600 {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  .eyebrow {
    @apply text-vis-white;
  }
  .secondary-button {
    @apply border-0;
    padding: 18px 2rem;
  }
  .tertiary-button {
    @apply mt-3 decoration-2 text-white;
    .tertiary-border {
      @apply text-white underline underline-offset-[6px];
    }
    &:hover,
    &:active {
      @apply text-white;
    }
  }
  .tertiary-button.with-arrow {
    @apply no-underline;
    &::after {
      background-color: white;
    }
  }
  .highlighted-text-modal-1,
  .highlighted-text-modal-2 {
    @apply text-link-dark-default;
  }

  .wysiwyg a {
    @apply text-link-dark-default;
    @apply underline;

    &.primary-button {
      @apply text-cta-primary-default mt-3 no-underline;
      @media (max-width: 640px) {
        width: 100% !important;
      }
      &:hover {
        @apply bg-visBtn-primaryHover;
      }
      &:active {
        @apply bg-visBtn-primaryPressed;
      }
    }
    &.secondary-button {
      @apply text-visBtn-secondary mt-3 no-underline;
      @media (max-width: 640px) {
        width: 100% !important;
      }
      &:hover {
        @apply bg-visBtn-secondaryHover text-white border-visBtn-secondaryHover;
      }
      &:active {
        @apply bg-visBtn-secondaryPressed text-white border-visBtn-secondaryPressed;
      }
    }
    &.tertiary-button {
      @apply text-cta-tertiary-default mt-3 underline underline-offset-[6px] decoration-2 text-white;
    }
    &.tertiary-button.with-arrow {
      @apply no-underline;
      &::after {
        background-color: white;
      }
      &:hover {
        @apply text-white;
        &::after {
          background-color: white;
        }
      }
    }

    figure {
      @apply flex my-v-spacing;

      &.--left {
        @apply justify-start;
      }
      &.--center {
        @apply justify-center;
      }
      &.--right {
        @apply justify-end;
      }

      img {
        @apply m-v-spacing;
      }
      &:last-child img {
        @apply mb-0;
      }
    }
  }
}
.bg-vis-white,
.bg-white,
.bg-vis-alt-1,
.bg-vis-grey-1,
.bg-vis-grey-2 {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-vis-regular;
  }
  p,
  ul {
    @apply text-vis-subtle;
  }
  .eyebrow {
    @apply text-vis-grey-3;
  }
  .primary-button {
    @apply text-vis-reverse mt-3 no-underline;
    @media (max-width: 640px) {
      width: 100% !important;
    }
    &:hover {
      @apply bg-visBtn-primaryHover;
    }
    &:active {
      @apply bg-visBtn-primaryPressed;
    }
  }
  .secondary-button {
    padding: 16px 2rem;
    @apply bg-vis-reverse text-visBtn-secondary border-visBtn-secondary border-2 no-underline mt-3;
    @media (max-width: 640px) {
      width: 100% !important;
    }
    &:hover {
      @apply bg-visBtn-secondaryHover text-vis-reverse border-visBtn-secondaryHover;
    }
    &:active {
      @apply text-vis-reverse bg-visBtn-secondaryPressed border-visBtn-secondaryPressed;
    }
  }
  .tertiary-button {
    @apply text-visBtn-tertiary mt-3;
    .tertiary-border {
      @apply text-visBtn-tertiary underline underline-offset-[6px] decoration-2;
      &:hover {
        @apply text-visBtn-tertiaryHover;
      }
      &:active {
        @apply text-visBtn-tertiaryPressed;
      }
    }
    &:hover {
      @apply text-visBtn-tertiaryHover;
    }
    &:active {
      @apply text-visBtn-tertiaryPressed;
    }
  }
  .highlighted-text-modal-1,
  .highlighted-text-modal-2 {
    @apply text-link-light-default;
  }
  .wysiwyg a {
    @apply text-link-light-default;
    @apply underline;
    &.primary-button {
      @apply text-vis-reverse mt-3 no-underline;
      @media (max-width: 640px) {
        width: 100% !important;
      }
      &:hover {
        @apply bg-visBtn-primaryHover;
      }
      &:active {
        @apply bg-visBtn-primaryPressed;
      }
    }
    &.secondary-button {
      padding: 16px 2rem;
      @apply bg-vis-reverse text-visBtn-secondary border-visBtn-secondary border-2 no-underline mt-3;
      @media (max-width: 640px) {
        width: 100% !important;
      }
      &:hover {
        @apply bg-visBtn-secondaryHover text-vis-reverse border-visBtn-secondaryHover;
      }
      &:active {
        @apply text-vis-reverse bg-visBtn-secondaryPressed border-visBtn-secondaryPressed;
      }
    }
    &.tertiary-button {
      @apply text-visBtn-tertiary mt-3 underline underline-offset-[6px] decoration-2;
      &:hover {
        @apply text-visBtn-tertiaryHover;
      }
      &:active {
        @apply text-visBtn-tertiaryHover;
      }
    }
    &.tertiary-button.with-arrow {
      @apply no-underline;
    }
  }
}

.wysiwyg-card {
  ul,
  ol {
    padding-inline-start: 14px;
  }
}

.wysiwyg-slide-card {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-bottom: 0.5rem;
    }
    &:last-child {
      @apply mb-0;
    }
  }
  .eyebrow {
    @apply font-extrabold text-sm leading-5 tracking-wider uppercase text-vis-grey-3 mb-2;
  }

  .disclaimer {
    @apply text-xs leading-3;
  }

  .disclaimer-sm {
    @apply text-xxs leading-3;
  }
}

.cta-lg {
  padding: 1rem 2rem;
}

.cta-md {
  width: auto;
  min-width: 139px;
  max-width: 280px;
  min-height: 48px;
}

.container {
  @apply py-15;
  @apply mx-auto;
  @apply px-v-spacing;
  max-width: 100%;

  @media (max-width: 767px) {
    @apply py-12;
  }
  @media (min-width: 640px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 1024px) {
    max-width: 960px;
  }
  @media (min-width: 1280px) {
    @apply max-w-container;
  }
}

.slider-container .swiper {
  @apply grid grid-cols-2;
  max-width: 100%;
  overflow: visible !important;
}
.swiper-wrapper {
  grid-column: 1 / span 2;
  overflow: visible;
}
.slider-inner-wrapper {
  @apply mx-auto;
  @apply px-v-spacing;
  @media (max-width: 767px) {
    @apply pt-12 pb-10;
  }
  @media (min-width: 640px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 1024px) {
    max-width: 960px;
  }
  @media (min-width: 1280px) {
    max-width: 1140px;
  }
}

.slider-section {
  overflow: hidden !important;
}

.slider-container {
  padding-top: 24px;
}

.aspect-ratio-16-9 {
  position: relative;
  padding-bottom: calc(9 / 16 * 100%);
  overflow: hidden;

  // For old browsers that don't support min(), YouTube videos will span the full width.
  @supports not (padding-bottom: calc(9 / 16 * 100%)) {
    padding-bottom: 56.25%; // 9 / 6 * 100
    max-width: none;
  }

  > figure {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: auto;
    bottom: 0;
    left: auto;

    > iframe {
      height: 100%;
    }
  }

  > iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: auto;
    bottom: 0;
    left: auto;
  }
}

.wistia-video {
  margin: 0 auto;

  @screen lg {
    &.--75 {
      width: 75%;
    }
    &.--50 {
      width: 50%;
    }
    &.--25 {
      width: 25%;
    }
    &.--100 {
      width: 100%;
    }
  }
}

.rec-dec-video {
  display: block;

  .poster {
    @apply absolute h-full w-1/2 cursor-pointer;

    &__img {
      @apply pointer-events-none w-full object-cover absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    }
  }

  &.--left {
    .poster,
    iframe,
    video {
      left: 0;
    }
  }

  &.--center {
    .poster,
    iframe,
    video {
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }

  &.--right {
    .poster,
    iframe,
    video {
      right: 0;
    }
  }

  &.--100 {
    .poster,
    iframe,
    video {
      display: inline-block;
      max-width: none;
      width: 100%;
      height: 100%;
    }
  }

  // Mobile & tablet - show the video/iframe/poster at full-width
  &.--75,
  &.--50,
  &.--25 {
    padding-bottom: calc(9 / 16 * 100%);

    .poster,
    iframe,
    video {
      width: 100%;
    }
  }

  // Desktop and larger - show the video at the specified width percentage
  @screen lg {
    &.--75 {
      padding-bottom: calc(9 / 16 * 75%);

      .poster,
      iframe,
      video {
        width: 75%;
      }
    }

    &.--50 {
      padding-bottom: calc(9 / 16 * 50%);

      .poster,
      iframe,
      video {
        width: 50%;
      }
    }

    &.--25 {
      padding-bottom: calc(9 / 16 * 25%);

      .poster,
      iframe,
      video {
        width: 25%;
      }
    }
  }
}
